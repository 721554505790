.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  @apply p-0;
}

.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  @apply bg-transparent p-0;
}

.ant-collapse-borderless {
  @apply bg-transparent;
}
