.ant-breadcrumb .ant-breadcrumb-separator {
  @apply mx-0 flex items-center;
}

.ant-breadcrumb a {
  @apply h-full rounded-md px-8 py-4 text-14 font-medium leading-20 text-gray-600;
}
.ant-breadcrumb li {
  @apply !flex !items-center;
}

.ant-breadcrumb li:last-child {
  @apply rounded-md bg-primary-50 px-8 py-4 text-14 font-medium leading-20 text-primary-500-base;
}
