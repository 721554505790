.confirm-steps
  > .ant-steps-item.ant-steps-item-finish
  .ant-steps-item-tail:after {
  @apply !w-full !bg-blue-1;
}

.confirm-steps > .ant-steps-item.ant-steps-item-finish .ant-steps-item-tail {
  @apply select-none px-15;
}

.confirm-steps > .ant-steps-item.ant-steps-item-process .ant-steps-item-tail {
  @apply select-none pl-16 pr-15;
}

.confirm-steps div.ant-steps-item-content {
  @apply !w-full select-none;
}

.confirm-steps div.ant-steps-item-content > div.ant-steps-item-title {
  @apply flex !truncate;
}

.main-confirm-collapse .ant-collapse-header-text {
  @apply !flex-auto;
}
