/* Select input css */
.select-status .ant-select-selector {
  @apply !border-0 !bg-transparent !py-0;
}

.select-status.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  @apply !shadow-none !outline-0;
}

/* Select dropdown listing css */
.status-tag-dropdown.ant-select-dropdown {
  @apply rounded border border-neutral-700 bg-primary-50;
}

.status-tag-dropdown.ant-select-dropdown .ant-select-item:last-child {
  @apply border-b-0;
}

.status-tag-dropdown.ant-select-dropdown .ant-select-item {
  @apply border-b border-gray-300 px-8 py-10;
}

.status-tag-dropdown.ant-select-dropdown .rc-virtual-list-holder-inner {
  @apply px-10;
}

.status-tag-dropdown.ant-select-dropdown .ant-select-item:hover {
  @apply bg-primary-25;
}
