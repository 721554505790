.ant-tabs .ant-tabs-nav {
  @apply px-24;
}
.ant-tabs-top .ant-tabs-nav {
  @apply !mb-8;
}
.ant-tabs .ant-tabs-tab {
  @apply px-10 py-8 lg:px-14 lg:py-12;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  @apply text-14 font-normal leading-20 text-gray-800;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply font-semibold text-brand-color;
}
.ant-tabs-nav::before {
  @apply border-border;
}
.ant-tabs .ant-tabs-ink-bar {
  @apply border-2 border-brand-color;
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  @apply m-0;
}
