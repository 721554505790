.primary-switch {
  @apply h-[20px] min-w-[36px] bg-gray-200;
}
.primary-switch:hover:not(.ant-switch-disabled) {
  @apply bg-gray-200;
}
.primary-switch.ant-switch-checked {
  @apply !bg-primary-500-base;
}
.primary-switch .ant-switch-handle {
  @apply h-[16px] w-[16px] shadow-filler-shadow;
}

.secondary-switch {
  @apply h-[15px] min-w-[30px] bg-toggle-off;
}
.secondary-switch:hover:not(.ant-switch-disabled) {
  @apply bg-toggle-off;
}
.secondary-switch.ant-switch-checked {
  @apply !bg-toggle-on;
}
.secondary-switch .ant-switch-handle {
  @apply h-[10px] w-[10px] shadow-filler-shadow;
}
.secondary-switch .ant-switch.ant-switch-checked .ant-switch-inner {
  @apply p-0;
}

.secondary-switch .ant-switch-inner {
  @apply flex items-center;
}

.secondary-switch .ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 15px) !important;
}
