.custom-delete-modal {
  @apply rounded border-modal-border;
}
.custom-delete-modal .ant-modal-header,
.custom-delete-modal .ant-modal-close {
  @apply hidden;
}
.custom-delete-modal.ant-modal .ant-modal-content .ant-modal-body {
  @apply px-16 pb-16 pt-32;
}
.custom-delete-modal .ant-modal-footer {
  @apply m-0 flex gap-[2px] border-none px-16 pb-24 pt-16;
}
.custom-delete-modal .ant-btn.ant-btn-lg {
  @apply !px-22;
}
