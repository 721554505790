.theme-form-select .ant-select {
  @apply h-[40px] rounded-lg;
}
.theme-form-select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  @apply border-border;
}
.theme-form-select
  .ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  @apply border border-primary-300 !shadow-input-field-focus-shadow;
}
.theme-form-select .ant-select-selector {
  @apply px-12 py-8;
}
.theme-form-select .ant-select-selection-item div span {
  @apply text-16 font-normal leading-24 text-gray-800;
}
.ant-select-dropdown {
  @apply rounded border border-neutral-700 bg-white p-0;
}
.ant-select-dropdown .ant-select-item {
  @apply rounded-none border-b border-border px-16 py-10  text-primary-10;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  @apply !bg-gray-50 !font-normal !text-primary-10;
}

.ant-select-dropdown .ant-select-item:hover {
  @apply bg-gray-300;
}

/* Data Limit Select css */
.data-limit-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.data-limit-select
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  @apply border-gray-300 shadow-input-field-shadow;
}

.data-limit-select .ant-select-single,
.data-limit-select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  @apply h-[44px];
}

.data-limit-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  @apply text-14 font-semibold leading-20;
}

.data-limit-select .ant-select-single .ant-select-selector {
  @apply text-gray-800;
}

.data-limit-select
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply px-15;
}

.data-limit-select .ant-select-arrow .anticon,
.data-limit-select .ant-select-arrow svg {
  @apply hidden;
}

.data-limit-select .ant-select-arrow::before {
  background-image: url("../../../../public/assets/images/mcs-data-limit-select-downarrow.svg");
  content: "";
  @apply h-[22px] w-[22px];
}

.data-limit-select
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  @apply pe-30;
}

.data-limit-select .ant-select {
  @apply min-w-[88px];
}

.data-limit-select .ant-select.ant-select-focused .ant-select-selector {
  @apply !border-primary-300 !shadow-input-field-focus-shadow;
}
/* Data Limit Select css ends */
.ant-select .ant-select-selection-placeholder {
  @apply text-16 font-normal leading-24 text-gray-500-base;
}
.rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-vertical.rc-virtual-list-scrollbar-visible {
  @apply !w-[4px];
}
.rc-virtual-list-scrollbar-thumb {
  @apply h-[49px] rounded-[21px] !bg-gray-300;
}
/* ant-select-multiple  */
.ant-select-multiple .ant-select-selector {
  @apply rounded-[8px] border border-solid border-gray-300 px-12 py-7 shadow-input-field-shadow;
}
.ant-select-selection-overflow-item .ant-select-selection-item {
  @apply h-auto rounded-[200px] bg-gray-300 px-20 py-11;
}
.ant-select-multiple .ant-select-selection-item-content {
  @apply me-8 text-16 font-medium leading-24 text-gray-800;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  @apply h-[11px] w-[11px] fill-gray-800;
}
.ant-select-item-option-state .anticon-check svg {
  @apply h-[15px] w-[15px] fill-blue-1;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  @apply border-gray-300;
}
.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  @apply !border-primary-300 !shadow-input-field-focus-shadow;
}
.ant-select .ant-select-arrow {
  @apply text-gray-500-base;
}
.ant-select-single .ant-select-selector {
  @apply rounded-lg;
}
.ant-select-multiple .ant-select-selector:after {
  @apply leading-20;
}
.filter-select-input .ant-select-single,
.filter-select-input
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  @apply !h-[40px];
}

.filter-select-input .ant-select .ant-select-selection-placeholder {
  @apply text-14 font-normal leading-20 text-brand-color;
}
.filter-select-input
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply px-16;
}
.filter-select-input .ant-select-arrow svg path {
  @apply stroke-brand-color;
}
.validity-dropdown .ant-select-item-option {
  @apply !py-10 !pe-12 !ps-24;
}
.validity-dropdown .ant-select-item.ant-select-item-option:hover {
  @apply bg-white;
}
.validity-dropdown .ant-select-item.ant-select-item-option:nth-child(2) {
  @apply bg-error-danger-50;
}
.validity .ant-select-selection-placeholder {
  @apply !pe-27;
}
.type-dropdown {
  @apply !w-[150px];
}
.type-dropdown .ant-select-item-option-content {
  @apply text-12 font-normal leading-18 text-primary-10;
}
.status-dropdown {
  @apply !w-[140px];
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply border-gray-300 bg-gray-200 text-gray-500-base shadow-input-field-shadow;
}
.general-settings
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  @apply !border-gray-300 !bg-gray-50 !text-gray-500-base shadow-input-field-shadow;
}
.ant-select-item-option-content div span {
  @apply text-12 font-normal leading-18 text-primary-10;
}
.ant-group-select-input {
  @apply !rounded border-neutral-700 bg-primary-50;
}
.ant-group-select-input .ant-select-item.ant-select-item-group {
  @apply !border-none bg-primary-50 px-16 pb-8 pt-10 text-12 font-medium leading-18 text-gray-700 hover:bg-primary-50;
}
.ant-group-select-input .ant-select-item.ant-select-item-option-grouped {
  @apply bg-primary-50 pt-0 hover:bg-primary-50;
}
.ant-group-select-input
  .ant-select-item.ant-select-item-option-grouped
  .ant-select-item-option-content {
  @apply text-12 font-medium leading-18 text-gray-400;
}
.ant-group-select-input .ant-select-item-option-active {
  @apply !bg-primary-50;
}
