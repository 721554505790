.custom-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  @apply hover:text-white;
}
.custom-button.primary-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  @apply text-white opacity-80 hover:bg-primary-500-base;
}

.ant-btn.ant-btn-lg {
  @apply rounded-md;
}

.primary-btn span,
.secondary-btn span,
.delete-btn span {
  @apply font-inter text-14 font-semibold leading-20;
}

.primary-btn span {
  @apply text-white;
}

.secondary-btn span {
  @apply text-primary-500-base;
}

.delete-btn span {
  @apply text-error-danger-500-base;
}

.custom-button.secondary-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  @apply border-primary-500-base;
}

.custom-button.delete-btn.ant-btn-default:not(:disabled):not(
    .ant-btn-disabled
  ):hover {
  @apply border-error-danger-500-base;
}
/* disable primary btn */
.custom-button.primary-btn.ant-btn-default:disabled {
  @apply border-primary-500-base bg-primary-500-base text-white opacity-60;
}
.custom-button.info-link.ant-btn:not(:disabled):focus-visible {
  @apply outline-none outline-0 outline-offset-0 transition-none;
}

.ant-skeleton.ant-skeleton-element .ant-skeleton-button-lg {
  height: 100px;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background-image: linear-gradient(
    90deg,
    #ccdee9 25%,
    #b1daf0 37%,
    #69a6c8 63%
  ) !important;
}

.ant-modal .ant-modal-content {
  padding: unset;
}
.ant-modal .ant-modal-header {
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 24px;
}

.ant-modal .ant-modal-footer {
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;
}

.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #c8dcea;
  height: 5px;
}
