.custom-collapse {
  @apply !rounded-none !border-none !bg-transparent;
}
.custom-collapse .ant-collapse-item {
  @apply !rounded-none border-b-0 !border-none;
}
.custom-collapse .ant-collapse-header {
  @apply px-44 py-8;
}
.custom-collapse.ant-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-expand-icon {
  @apply hidden;
}
.custom-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text
  .icon {
  transform: rotateZ(180deg);
}
.custom-collapse
  .ant-collapse-item
  .ant-collapse-header
  .ant-collapse-header-text
  .shrink-back-text {
  @apply hidden;
}
.custom-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text
  .expand-text {
  @apply hidden;
}

.custom-collapse
  .ant-collapse-item.ant-collapse-item-active
  .ant-collapse-header
  .ant-collapse-header-text
  .shrink-back-text {
  @apply block;
}
.custom-collapse.ant-collapse .ant-collapse-item .ant-collapse-header {
  @apply !py-8 px-44;
}

.custom-collapse .ant-collapse-content .ant-collapse-content-active {
  @apply !rounded-none;
}
.custom-collapse.ant-collapse .ant-collapse-content {
  @apply !border-none !bg-primary-50;
}
.custom-collapse.ant-collapse .ant-collapse-content .ant-btn.ant-btn-block {
  @apply !w-auto;
}
