@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
/* marinair */
.custom-modal.ant-modal .ant-modal-content {
  @apply p-0;
}
.custom-modal.ant-modal .ant-modal-content .ant-modal-header {
  @apply mb-0 border-b border-border p-24;
}
.custom-modal.ant-modal .ant-modal-close {
  @apply hidden;
}
.ant-modal-title {
  @apply flex items-center justify-between;
}
.custom-modal.ant-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  @apply text-16 font-semibold leading-24 text-gray-800;
}
.custom-modal.ant-modal
  .ant-modal-content
  .ant-modal-header
  .ant-modal-title
  .title {
  @apply text-16 font-semibold leading-24 text-gray-800;
}
.custom-modal.ant-modal .ant-modal-content .ant-modal-body {
  @apply !max-h-[415px] overflow-auto p-24;
}
.full-height.ant-modal .ant-modal-content .ant-modal-body {
  @apply !max-h-full overflow-auto p-16;
}
.custom-modal.ant-modal .ant-modal-close,
.custom-modal.ant-modal .ant-modal-close:hover {
  @apply bg-transparent text-gray-800;
}
.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}
.ant-modal-title {
  @apply flex items-center;
}
.ant-modal-close-icon svg {
  @apply h-[20px] w-[20px];
}
.custom-common-modal.ant-modal {
  @apply !rounded-none;
}
.custom-common-modal.ant-modal .ant-modal-header {
  @apply !rounded-none;
}
.custom-common-modal.ant-modal .ant-modal-content {
  @apply !rounded-none p-0;
}
.custom-common-modal .ant-modal-footer {
  @apply mt-0 border-border px-24 pb-24;
}
.custom-common-modal .ant-modal-footer .ant-btn {
  @apply min-w-[138px];
}

.upload-modal.ant-modal {
  @apply !rounded;
}
.upload-modal.ant-modal .ant-modal-content {
  @apply !rounded;
}
.upload-modal.ant-modal .ant-modal-header {
  @apply !rounded;
}
.custom-common-modal .ant-modal-header {
  @apply sticky top-0 z-10 shrink-0;
}
.custom-remarks-modal .ant-modal-content {
  @apply rounded-none bg-primary-50 px-24 py-0 shadow-none;
}
.custom-remarks-modal .ant-modal-content .ant-modal-header {
  @apply border-none bg-primary-50 px-44 pb-0 pt-20;
}
.custom-remarks-modal .ant-modal-content .ant-modal-body {
  @apply rounded-none px-44  pb-20 pt-2;
}
.custom-remarks-modal .ant-modal-title .modal-icon {
  @apply hidden;
}
.custom-remarks-modal .ant-modal-title .title {
  @apply !text-20 !font-bold !leading-30 !text-primary-400;
}
.custom-remarks-modal .ant-modal-title svg path {
  @apply !fill-primary-400;
}
.custom-remarks-modal .modal-list li {
  @apply list-disc text-12 font-normal leading-18 text-primary-400;
}

.custom-remarks-modal .ant-modal-footer {
  @apply hidden;
}

/* .ant-modal-wrap.ant-modal-centered {
  @apply !my-10;
} */
