.custom-horizontal-steps.ant-steps.ant-steps-dot .ant-steps-item-icon,
.custom-horizontal-steps.ant-steps.ant-steps-dot.ant-steps-small
  .ant-steps-item-icon {
  @apply h-[12px] w-[12px];
}
.custom-horizontal-steps.ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  @apply relative  border border-[#808CB7] bg-[#D1D5E5];
}
.custom-dot
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  @apply bg-transparent;
}
.custom-horizontal-steps.ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  @apply border border-[#808CB7] bg-[#D1D5E5];
}

.custom-horizontal-steps .ant-steps-item-tail {
  @apply !me-0 !ms-65;
}

.custom-horizontal-steps .ant-steps-item-tail:after {
  @apply mt-3 !h-0 !w-full border-b border-dashed border-primary-200 py-0;
}
