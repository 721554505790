.countrySelectWrapper {
  position: relative;
}

.countrySelectWrapper > .prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 3rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countrySelectWrapper .ant-select .ant-select-selector {
  padding-left: calc(3rem - 7px) !important;
}

.countrySelectWrapper .ant-select .ant-select-selector input {
  padding-left: calc(2rem - 2px) !important;
}

.countrySelectWrapper .ant-select-selection-placeholder {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 400;
}
