.custom-vertical-steps.ant-steps.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-tail {
  @apply py-0;
}

.custom-vertical-steps .ant-steps-item-tail:after {
  @apply border border-dashed border-primary-200 bg-transparent;
}
.custom-vertical-steps.ant-steps
  .ant-steps-item-wait
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  @apply bg-primary-5;
}
.custom-vertical-steps.ant-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  @apply bg-primary-5;
}
.custom-vertical-steps.ant-steps.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-title {
  @apply text-14 font-medium leading-20 text-[#333F69];
}
.custom-vertical-steps.ant-steps
  .custom-dot
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon
  .ant-steps-icon-dot {
  @apply bg-transparent;
}
.custom-vertical-steps.ant-steps.ant-steps-dot
  .ant-steps-item-process
  .ant-steps-item-icon,
.custom-vertical-steps.ant-steps.ant-steps-dot.ant-steps-small
  .ant-steps-item-process
  .ant-steps-item-icon {
  @apply h-[4px] w-[4px];
}
.custom-vertical-steps.ant-steps.ant-steps-dot .ant-steps-item-icon,
.custom-vertical-steps.ant-steps.ant-steps-dot.ant-steps-small
  .ant-steps-item-icon {
  @apply h-[4px] w-[4px];
}
.custom-vertical-steps.ant-steps.ant-steps-vertical
  .ant-steps-item
  .ant-steps-item-icon {
  @apply me-7;
}

.custom-vertical-steps.ant-steps.ant-steps-vertical.ant-steps-dot.ant-steps-small
  .ant-steps-item
  .ant-steps-item-container
  .ant-steps-item-tail {
  @apply left-[-2.4px];
}
.custom-vertical-steps.ant-steps.ant-steps-dot
  .ant-steps-item-process
  .ant-steps-icon:first-child
  .ant-steps-icon-dot,
.ant-steps.ant-steps-dot.ant-steps-small
  .ant-steps-item-process
  .ant-steps-icon:first-child
  .ant-steps-icon-dot {
  @apply ms-1;
}

/* Remove space for titles in the Steps component */
.custom-horizontal-steps.no-title-space .ant-steps-item-title {
  display: none; /* Hides the title completely */
}

.custom-horizontal-steps.no-title-space .ant-steps-item {
  padding-bottom: 0; /* Adjusts padding caused by hidden titles */
}
