.approved {
  @apply bg-success-50 text-success-700;
}

.pending {
  @apply bg-warning-50 text-warning-700;
}
.rejected {
  @apply bg-error-danger-50 text-red-700;
}
