.otp-input{
    width: 62px;
    height: 68px;
        @media (max-width:991px) {
            width: 45px;
            height: 51px;
        }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}